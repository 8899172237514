import _ from "lodash";
import $ from "jquery";
import swal from "sweetalert";
import Tagify from "@yaireo/tagify";
import DataTables from "datatables.net";
import 'datatables.net-bs5/js/dataTables.bootstrap5.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// import {Main} from './libraries/library-arcgis-js/Main';
// window.libArgisJs = new Main();
// window.ESRI = {};

window.$ = $;
window.$.DataTable = DataTables;
window.swal = swal;
window.Tagify = Tagify;

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
